import '../App.css';
import React from 'react';
function DeleteSuccessPage(){
    return(
        <>
            <div className="App">
                DELETE SUCCESS
            </div>
        </>
    );
}

export default DeleteSuccessPage;