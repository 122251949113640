import '../App.css';
import React from "react";

function YouTubeList(props){
    return(
        <>
            <div className="App">
                <table cellPadding="10" cellSpacing="10" border="5">
                    {props.youTubeList.map((entry, index) => {
                        return (
                            <tbody id={"ytdesctbid" + index} key={"ytdesctbkey" + index}>
                                <tr id={"ytdesctrid" + index} key={"ytdesctrkey" + index}>
                                    <td id={"ytdesctdid" + index} key={"ytdesctdkey" + index} dangerouslySetInnerHTML={{__html: entry.descriptionHtml}}></td>
                                    <td id={"ytembedtdid" + index} key={"ytembedtdkey" + index} dangerouslySetInnerHTML={{__html: entry.youTubeEmbed}}></td>
                                </tr>
                            </tbody>
                        );
                    })}
                </table>
            </div>
        </>
    );
}

export default YouTubeList;