import '../App.css';
import AnalogArchiveMusicPlayer from "./AnalogArchiveMusicPlayer";
import React, {useState, useEffect} from "react";

function AnalogArchiveMediaFolder(props){
    const [songs, setSongs] = useState([]);
    useEffect(() => {
        const analogArchiveFetchRequest = props.analogArchiveBaseDomainUrl + "GetSongs.php?mediaFolder=" + props.analogArchiveMediaFolder;
        console.log(props.analogArchiveMediaFolder);
        fetch(analogArchiveFetchRequest)
              .then((res) => {
                  const contentType = res.headers.get("content-type");
                  if (contentType && contentType.indexOf("application/json") !== -1) {
                      return res.json().then(data => {
                          const errorStatus = res.status;
                          if (errorStatus === 200) {
                              return data;
                          } else {
                              throw new Error("ANALOG ARCHIVE FAILED TO RETURN RESULTS:" + errorStatus + " VIEW SERVER LOG FOR DETAILS");
                          }
                      })
                      .then(resJsonObject => {
                          let newSongs = [];
                          for(let i=0; i<resJsonObject.length; i++){
                              newSongs.push([resJsonObject[i].artist,
                                  resJsonObject[i].album,
                                  resJsonObject[i].track,
                                  resJsonObject[i].title,
                                  resJsonObject[i].file]);
                          }
                          setSongs(newSongs);
                      })
                      .catch((message) => {
                              console.warn(message);
                      });
                }
                else {
                        return res.text().then(text => {
                            // this is text, do something with it
                            console.log("FETCH TO ANALOG ARCHIVE RETURNED:" + text);
                            throw new Error("FETCH TO ANALOG ARCHIVE RETURNED:" + text);
                        });
                    }
        });
    }, [props.analogArchiveMediaFolder, props.analogArchiveBaseDomainUrl]);
    return(
        <AnalogArchiveMusicPlayer handleAudioEnded={props.handleAudioEnded} songs={songs} handleAudioPlay={props.handleAudioPlay} analogArchiveBaseDomainUrl={props.analogArchiveBaseDomainUrl} />
    );
}

export default AnalogArchiveMediaFolder;
