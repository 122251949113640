import '../App.css';
import React from "react";
import SelectPasswordsPage from "./SelectPasswordsPage";
function DeleteForm( props) {
    return(
        <>
            <div className="App">
                <input type='text' name='deletePassword' id='deletePassword' placeholder="Password" onChange={(element) => props.handleDeletePasswordChange(element, props.setDeletePassword)}/>
                <br/>
                <input type='button' name='submit' id='submit' value='DELETE!' onClick={(element) => props.handleDeleteSubmitClick(element, props.deletePassword, props.setShowDeleteForm, props.setShowDeleteSuccess, props.baseDomainUrl, props.passwordCrudResource )}/>
            </div>
            <SelectPasswordsPage baseDomainUrl={props.baseDomainUrl}/>
        </>
    );
}

export default DeleteForm;