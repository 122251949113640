import '../App.css';
import React, {useState, useEffect} from "react";
import ImageList from "./ImageList";
import AudioList from "./AudioList";
import VideoList from "./VideoList";
import PdfList from "./PdfList";
import OtherList from "./OtherList";
import ZipList from "./ZipList";


function ServerFolder(props){
    const [fileListingArray, setFileListingArray] = useState([]);
    console.log("SERVER FOLDER:" + JSON.stringify({location : props.serverFolder}));
    useEffect(() => {
        fetch(props.baseDomainUrl + 'filelisting', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({location : props.serverFolder})
        })
            .then((res => {
                const resJson = res.json();
                const errorStatus = res.status;
                if (errorStatus === 200) {
                    return resJson;
                } else {
                    throw new Error("SERVER FOLDER FILE LISTING/JSON RESPONSE STATUS:" + errorStatus + " VIEW SERVER LOG FOR DETAILS");
                }
            }))
            .then(res => {
                let newFileListingArray = [];
                JSON.parse(res.fileListingArray).map((value) => newFileListingArray.push(props.serverFolder.replace("../", "https://") + value));
                setFileListingArray(newFileListingArray);
            })
            .catch(err => {
                console.error("FETCH COMMAND ERROR:" + err);
            });
    }, [props.baseDomainUrl, props.serverFolder]);
    return(
        <>
            <ImageList id="serverFolderImages" key="serverFolderImages" images={fileListingArray.filter((file) =>
                file.toLowerCase().endsWith(".gif") ||
                file.toLowerCase().endsWith(".tif") ||
                file.toLowerCase().endsWith(".tiff") ||
                file.toLowerCase().endsWith(".png") ||
                file.toLowerCase().endsWith(".jpeg") ||
                file.toLowerCase().endsWith(".jpg"))}/>
            <AudioList id="serverFolderAudio" key="serverFolderAudio" audio={fileListingArray.filter((file) =>
                file.toLowerCase().endsWith(".mp3") )}/>
            <VideoList id="serverFolderVideo" key="serverFolderVideo" videos={fileListingArray.filter((file) =>
                file.toLowerCase().endsWith(".mp4") ||
                file.toLowerCase().endsWith(".m4v")
            )}/>
            <PdfList id="serverFolderPdf" key="serverFolderPdf" pdfs={fileListingArray.filter((file) =>
                file.toLowerCase().endsWith(".pdf")
        )}/>
            <ZipList id="serverFolderZip" key="serverFolderZip" zips={fileListingArray.filter((file) =>
                file.toLowerCase().endsWith(".zip")
            )}/>
            <OtherList id="serverFolderOther" key="serverFolderOther" others={fileListingArray.filter((file) => {
                const lowerCaseFile = file.toLowerCase();
                return !(
                    lowerCaseFile.endsWith(".gif") ||
                    lowerCaseFile.endsWith(".tif") ||
                    lowerCaseFile.endsWith(".tiff") ||
                    lowerCaseFile.endsWith(".png") ||
                    lowerCaseFile.endsWith(".jpeg") ||
                    lowerCaseFile.endsWith(".jpg") ||
                    lowerCaseFile.endsWith(".mp3") ||
                    lowerCaseFile.endsWith(".mp4") ||
                    lowerCaseFile.endsWith(".m4v") ||
                    lowerCaseFile.endsWith(".pdf") ||
                    lowerCaseFile.endsWith(".zip")
                );
            })}/>
        </>

    );
}

export default ServerFolder;
