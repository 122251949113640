import '../App.css';
import React from 'react';
function ZipList(props){
    return(
        <>
            <div className="App">
                {
                    props.zips.map((value, index) => {
                        return(
                            <>
                                <a key={"zip" + index} id={"zip" + index} href={value}>{value}</a>
                                <br />
                            </>
                        );
                        }
                    )
                }
            </div>
        </>
    );
}

export default ZipList;