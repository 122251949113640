import '../App.css';
import React, {useState, useEffect} from "react";

function SelectPasswordsPage(props){
    const [passwordsArray, setPasswordsArray] = useState([]);
    useEffect(() => {
        fetch(props.baseDomainUrl + 'passwords', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then((res => {
                const resJsonObject = res.json();
                const errorStatus = res.status;
                if (errorStatus === 200) {
                    return resJsonObject;
                } else {
                    throw new Error("SERVER FOLDER FILE LISTING/JSON RESPONSE STATUS:" + errorStatus + " VIEW SERVER LOG FOR DETAILS");
                }
            }))
            .then(resJsonObject => {
                let newPasswords = [];
                for(let i=0; i<resJsonObject.length; i++){
                    newPasswords.push([resJsonObject[i].password,
                        resJsonObject[i].payload]);
                }
                setPasswordsArray(newPasswords);
            })
            .catch(err => {
                console.error("FETCH COMMAND ERROR:" + err);
            });
    }, [props.baseDomainUrl]);
    return(
        <>
            <p><ul>
                {passwordsArray.map((value,index) => {
                        return (
                            <>
                            <li><b>{value[0]}</b>{value[1]}</li>
                            </>
                        );
                    })
                }
            </ul></p>
        </>

    );
}

export default SelectPasswordsPage;
