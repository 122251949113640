import '../App.css';
import React from 'react';
function InsertSuccessPage(){
    return(
        <>
            <div className="App">
                INSERT SUCCESS
            </div>
        </>
    );
}

export default InsertSuccessPage;