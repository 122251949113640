import '../App.css';
import React from 'react';
function OtherList(props){
    return(
        <>
            <div className="App">
                {
                    props.others.map((value, index) => {
                        return(
                            <>
                                <span key={"other" + index} id={"other" + index}>{value}</span>
                                <br />
                            </>
                        );
                        }
                    )
                }
            </div>
        </>
    );
}

export default OtherList;