import '../App.css';
import React from "react";
import SelectPasswordsPage from "./SelectPasswordsPage";
function InsertForm( props) {
    return(
        <>
            <div className="App">
                <input type='password' name='insertPassword' id='insertPassword' placeholder="Password"  autoFocus="autofocus" onChange={(element) => props.handleInsertPasswordChange(element, props.setInsertPassword)}/>
                <br/>
                <input type='text' name='insertPayload' id='insertPayload' placeholder="JSON Payload" onChange={(element) => props.handleInsertPayloadChange(element, props.setInsertPayload)}/>
                <br/>
                <table>
                    <tbody>
                    <thead>Valid json payloads.</thead>
                <tr>
                    <td>
                image page:
                    </td>
                    <td>
                    {JSON.stringify({
                        "authenticated" : true,
                        "images" : ["https://stuffedanimalwar.com/images/"]})}
                </td>
                </tr>
                <tr>
                    <td>
                        html page:
                    </td>
                    <td>
                    {JSON.stringify({
                        "authenticated" : true,
                        "htmlDoc" : "https://stuffedanimalwar.com/html/referrals.html"})}
                    </td>
                </tr>
                <tr>
                    <td>
                youtube page:</td>
                <td> {JSON.stringify({
                    "authenticated": true,
                    "youTubeList":
                        [
                            {
                                "descriptionHtml":
                                    "Kiss me with your mouth<br />Your love is better than wine<br />But wine is all I have<br />Will your love ever be mine?<br />",
                                "youTubeEmbed":
                                    "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube-nocookie.com/embed/AV5szoyanfg\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>"
                            }
                        ]
                })}</td>
                </tr>
                <tr>
                    <td>
                video page:
                    </td>
                    <td>
                        {JSON.stringify({
                    "authenticated" : true,
                    "videos":
                        [
                            "https://stuffedanimalwar.com/images/vertical.mp4"
                        ]
                    }
                    )
                }
                    </td>
                    </tr>
                    <tr>
                        <td>
                            audio page:
                        </td>
                        <td>
                            {JSON.stringify({
                                    "authenticated" : true,
                                    "audio":
                                        [
                                            "https://analogarchive.com/digital/03%20The%20Edge%20Of%20Midnight%20(Sparse).mp3"
                                        ]
                                }
                            )
                            }
                        </td>
                    </tr>
                <tr>
                <td>
                    message:
                </td>
                <td>
                    {JSON.stringify(
                        {
                            "authenticated" : true,
                            "message" : "password inserted"
                        }
                        )
                    }
                </td>
                </tr>
                <tr>
                    <td>
                        analog archive folder:
                    </td>
                    <td>
                        {JSON.stringify(
                            {
                                "authenticated" : true,
                                "analogArchiveMediaFolder" : "live/lastgasp"
                            }
                        )
                        }
                    </td>
                </tr>
                    <tr>
                        <td>
                            server folder:
                        </td>
                        <td>
                            {JSON.stringify(
                                {"serverFolder": "../stuffedanimalwar.com/VOLATILE/stoneroses/", "authenticated": true}
                            )
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            passwords:
                        </td>
                        <td>
                            <SelectPasswordsPage baseDomainUrl={props.baseDomainUrl}/>
                        </td>
                    </tr>
                </tbody>
                </table>
                <br/>
                <br/>
                <input type='button' name='submit' id='submit' value='INSERT!' onClick={(element) => props.handleInsertSubmitClick(element, props.insertPassword, props.insertPayload, props.setShowInsertForm,
                    props.setShowInsertSuccess, props.baseDomainUrl, props.passwordCrudResource)}/>
            </div>
        </>
    );
}

export default InsertForm;
