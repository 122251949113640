//WHEN CHANGING RESOURCES
//1. BASEDOMAINURL, SERVICEURLRESOURCE, PASSWORDCRUDRESOURCE
//3. PACKAGE.JSON
import './App.css';
import React  from 'react';
import { useState }  from 'react';
import ImageList from './components/ImageList';
import VideoList from './components/VideoList';
import AudioList from './components/AudioList';
import YouTubeList from "./components/YouTubeList";
import AnalogArchiveMediaFolder from "./components/AnalogArchiveMediaFolder";
import ServerFolder from "./components/ServerFolder";
import HtmlDoc from "./components/HtmlDoc";
import InsertForm from "./components/InsertForm";
import DeleteForm from "./components/DeleteForm";
import InsertSuccessPage from "./components/InsertSuccessPage";
import DeleteSuccessPage from "./components/DeleteSuccessPage";
import MainPasswordForm from "./components/MainPasswordForm";

function App() {
    const [showImages, setShowImages] = useState(false);
    const [showHtmlDoc, setShowHtmlDoc] = useState(false);
    const [showYouTubeList, setShowYouTubeList] = useState(false);
    const [showAnalogArchiveMediaFolder, setShowAnalogArchiveMediaFolder] = useState(false);
    const [showServerFolder, setShowServerFolder] = useState(false);
    const [showAudio, setShowAudio] = useState(false);
    const [showVideos, setShowVideos] = useState(false);
    const [showInsertForm, setShowInsertForm] = useState(false);
    const [showInsertSuccess, setShowInsertSuccess] = useState(false);
    const [showDeleteForm, setShowDeleteForm] = useState(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);
    const [audio, setAudio] = useState([]);
    const [htmlDoc, setHtmlDoc] = useState("");
    const [youTubeList, setYouTubeList] = useState("");
    const [analogArchiveMediaFolder, setAnalogArchiveMediaFolder] = useState("");
    const [serverFolder, setServerFolder] = useState("");
    const [password, setPassword] = useState("");
    const [insertPassword, setInsertPassword] = useState("");
    const [insertPayload, setInsertPayload] = useState("");
    const [deletePassword, setDeletePassword] = useState("");
    const [analogArchiveBaseDomainUrl,] = useState("https://analogarchive.com/"); //analogarchive
    const [baseDomainUrl,] = useState("https://stuffedanimalwar.com:55555/");
    const[serviceUrlResource,] = useState("stuffedanimalwarpassword/");
    const[passwordCrudResource,] = useState("password/");
    if (showImages) {
        return (
            <ImageList images={images}/>
        );
    }
    else if (showVideos) {
        return (
            <VideoList videos={videos}/>
        );
    }
    else if (showAudio) {
        return (
            <AudioList audio={audio}/>
        );
    }
    else if(showYouTubeList) {
        return (
            <YouTubeList youTubeList={youTubeList} />
        );
    }
    else if(showAnalogArchiveMediaFolder) {
        return (
            <AnalogArchiveMediaFolder handleAudioEnded={handleAudioEnded} analogArchiveMediaFolder={analogArchiveMediaFolder} handleAudioPlay={handleAudioPlay} analogArchiveBaseDomainUrl={analogArchiveBaseDomainUrl} />
        );
    }
    else if(showServerFolder) {
        return (
            <ServerFolder baseDomainUrl={baseDomainUrl} serverFolder={serverFolder}  />
        );
    }
    else if (showHtmlDoc) {
        return (
            <HtmlDoc htmlDoc={htmlDoc} />
        );

    }
    else if (showInsertForm) {
        return (
            <InsertForm handleInsertPasswordChange={handleInsertPasswordChange}
                        handleInsertPayloadChange={handleInsertPayloadChange}
                        handleInsertSubmitClick={handleInsertSubmitClick}
                        insertPassword={insertPassword}
                        insertPayload={insertPayload}
                        setShowInsertForm={setShowInsertForm}
                        setShowInsertSuccess={setShowInsertSuccess}
                        baseDomainUrl={baseDomainUrl}
                        passwordCrudResource={passwordCrudResource}
                        setInsertPassword={setInsertPassword}
                        setInsertPayload={setInsertPayload}
                        setShowDeleteForm={setShowDeleteForm}/>
        );
    } else if (showDeleteForm) {
        return (
            <DeleteForm handleDeletePasswordChange={handleDeletePasswordChange}
                        handleDeleteSubmitClick={handleDeleteSubmitClick}
                        deletePassword={deletePassword}
                        setShowDeleteForm={setShowDeleteForm}
                        setShowDeleteSuccess={setShowDeleteSuccess}
                        baseDomainUrl={baseDomainUrl}
                        passwordCrudResource={passwordCrudResource}
                        setDeletePassword={setDeletePassword} />
        );
    }
    else if (showInsertSuccess) {
        return (
            <InsertSuccessPage />
        );
    }
    else if (showDeleteSuccess) {
        return (
            <DeleteSuccessPage />
        );
    } else {
        return (
            <MainPasswordForm
                handlePasswordChange={handlePasswordChange}
                setPassword={setPassword}
                handleSubmitClick={handleSubmitClick}
                setShowImages={setShowImages}
                setShowVideos={setShowVideos}
                setImages={setImages}
                setVideos={setVideos}
                setShowHtmlDoc={setShowHtmlDoc}
                setHtmlDoc={setHtmlDoc}
                setYouTubeList={setYouTubeList}
                setShowYouTubeList={setShowYouTubeList}
                password={password}
                baseDomainUrl={baseDomainUrl}
                serviceUrlResource={serviceUrlResource}
                setShowInputForm={setShowInsertForm}
                setShowAudio={setShowAudio}
                setAudio={setAudio}
                setAnalogArchiveMediaFolder={setAnalogArchiveMediaFolder}
                setServerFolder={setServerFolder}
                setShowAnalogArchiveMediaFolder={setShowAnalogArchiveMediaFolder}
                setShowServerFolder={setShowServerFolder}
                setShowDeleteForm={setShowDeleteForm}
            />
        );
    }
}

function handleInsertPasswordChange(element, setInsertPassword) {
    setInsertPassword(element.target.value);
}
function handleDeletePasswordChange(element, setDeletePassword) {
    setDeletePassword(element.target.value);
}
function handleInsertPayloadChange(element, setInsertPayload) {
    setInsertPayload(element.target.value);
}

function handlePasswordChange(element, setPassword) {
    setPassword(element.target.value);
}

function handleSubmitClick(element, setShowImages, setShowVideos,
                           setImages, setVideos, setShowHtmlDoc,
                           setHtmlDoc, setYouTubeList, setShowYouTubeList,
                           password, baseDomainUrl, serviceUrlResource,
                           setShowInputForm, setShowAudio, setAudio,
                           setAnalogArchiveMediaFolder, setServerFolder, setShowAnalogArchiveMediaFolder, setShowServerFolder, setShowDeleteForm) {
    fetch(baseDomainUrl + serviceUrlResource + '?credential=' + password)
        .then((res => {
            const resJson = res.json();
            const errorStatus = res.status;
            if (errorStatus === 200) {
                return resJson;
            } else {
                throw new Error("SELECT PAYLOAD BY PASSWORD RESPONSE STATUS:" + errorStatus + " VIEW SERVER LOG FOR DETAILS");
            }
        }))
        .then(res => {
            let jaemzwareAuthPayloadString = res[0].payload;
            console.log(`SELECT: PAYLOAD STRING:${jaemzwareAuthPayloadString}`);
            let jaemzwareAuthPayloadObject = JSON.parse(jaemzwareAuthPayloadString);
            console.log(`SELECT: PAYLOAD OBJECT:${jaemzwareAuthPayloadObject}`);
            if (!jaemzwareAuthPayloadObject.hasOwnProperty('authenticated')) throw new Error("SELECT: NO AUTHENTICATED STATUS" + JSON.stringify(jaemzwareAuthPayloadString));
            if (jaemzwareAuthPayloadObject.authenticated) {
                setShowInputForm(false);
                setShowImages(false);
                setShowVideos(false);
                setShowAudio(false);
                setShowHtmlDoc(false);
                setShowYouTubeList(false);
                setShowAnalogArchiveMediaFolder(false);
                setShowServerFolder(false);
                if (jaemzwareAuthPayloadObject.hasOwnProperty('images')) {
                    let newImages = [];
                    for (let i = 0; i < jaemzwareAuthPayloadObject.images.length; i++) {
                        newImages.push(jaemzwareAuthPayloadObject.images[i]);
                    }
                    setImages(newImages);
                    setShowImages(true);
                }
                else if (jaemzwareAuthPayloadObject.hasOwnProperty('videos')) {
                    let newVideos = [];
                    for (let i = 0; i < jaemzwareAuthPayloadObject.videos.length; i++) {
                        newVideos.push(jaemzwareAuthPayloadObject.videos[i]);
                    }
                    setVideos(newVideos);
                    setShowVideos(true);
                }
                else if (jaemzwareAuthPayloadObject.hasOwnProperty('audio')) {
                    let newAudio = [];
                    for (let i = 0; i < jaemzwareAuthPayloadObject.audio.length; i++) {
                        newAudio.push(jaemzwareAuthPayloadObject.audio[i]);
                    }
                    setAudio(newAudio);
                    setShowAudio(true);
                }
                else if (jaemzwareAuthPayloadObject.hasOwnProperty('htmlDoc')) {
                    setHtmlDoc(jaemzwareAuthPayloadObject.htmlDoc);
                    setShowHtmlDoc(true);
                } else if (jaemzwareAuthPayloadObject.hasOwnProperty('youTubeList')) {
                    setYouTubeList(jaemzwareAuthPayloadObject.youTubeList);
                    setShowYouTubeList(true);
                } else if (jaemzwareAuthPayloadObject.hasOwnProperty('analogArchiveMediaFolder')) {
                    setAnalogArchiveMediaFolder(jaemzwareAuthPayloadObject.analogArchiveMediaFolder);
                    setShowAnalogArchiveMediaFolder(true);
                } else if (jaemzwareAuthPayloadObject.hasOwnProperty('serverFolder')) {
                    setServerFolder(jaemzwareAuthPayloadObject.serverFolder);
                    setShowServerFolder(true);
                } else if(jaemzwareAuthPayloadObject.hasOwnProperty('showInsertForm')) {
                    setShowInputForm(true);
                } else if(jaemzwareAuthPayloadObject.hasOwnProperty('showDeleteForm')) {
                    setShowDeleteForm(true);
                } else {
                    throw new Error("SELECT: UNEXPECTED RESPONSE" + jaemzwareAuthPayloadString);
                }
            } else {
                throw new Error("SELECT: NOT AUTHENTICATED" + jaemzwareAuthPayloadString);
            }
        })
        .catch((message) => {
                console.error(message);
            }
        );
}

function handleInsertSubmitClick(element, insertPassword, insertPayload,
                                 setShowInsertForm, setShowInsertSuccess, baseDomainUrl,
                                 passwordCrudResource) {
    fetch(baseDomainUrl + passwordCrudResource + "?credential=" + insertPassword, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: insertPayload
    })
        .then((res => {
            const resJson = res.json();
            const errorStatus = res.status;
            if (errorStatus === 200) {
                return resJson;
            } else {
                throw new Error("INSERT PASSWORD/JSON RESPONSE STATUS:" + errorStatus + " VIEW SERVER LOG FOR DETAILS");
            }
        }))
        .then(res => {
            let jaemzwareAuthPayloadObject = res;
            let jaemzwareAuthPayloadString = JSON.stringify(jaemzwareAuthPayloadObject);
            console.log(`INSERT: PAYLOAD OBJECT:${jaemzwareAuthPayloadObject} PAYLOAD STRING:${jaemzwareAuthPayloadString}`);
            if (!jaemzwareAuthPayloadObject.hasOwnProperty('authenticated')) throw new Error("INSERT: NO AUTHENTICATED STATUS" + jaemzwareAuthPayloadString);
            if (jaemzwareAuthPayloadObject.authenticated) {
                if (jaemzwareAuthPayloadObject.hasOwnProperty('message') && jaemzwareAuthPayloadObject.message === "password inserted") {
                    setShowInsertForm(false);
                    setShowInsertSuccess(true);
                } else {
                    throw new Error("INSERT: UNEXPECTED RESPONSE" + jaemzwareAuthPayloadString);
                }
            } else {
                throw new Error("INSERT: NOT AUTHENTICATED" + jaemzwareAuthPayloadString);
            }
        })
        .catch(err => {
            console.error("INSERT: FETCH COMMAND ERROR:" + err);
        })
        .catch((message) => {
                console.error("INSERT: SERVER ERROR:" + message);
            }
        );
}


function handleDeleteSubmitClick(element, deletePassword, setShowDeleteForm,
                                 setShowDeleteSuccess, baseDomainUrl, passwordCrudResource) {
    fetch(baseDomainUrl + passwordCrudResource + "?credential=" + deletePassword, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
    })
        .then((res => {
            const resJson = res.json();
            const errorStatus = res.status;
            if (errorStatus === 200) {
                return resJson;
            } else {
                throw new Error("DELETE PASSWORD/JSON RESPONSE STATUS:" + errorStatus + " VIEW SERVER LOG FOR DETAILS");
            }
        }))
        .then(res => {
            let jaemzwareAuthPayloadObject = res;
            let jaemzwareAuthPayloadString = JSON.stringify(jaemzwareAuthPayloadObject);
            console.log(`DELETE: PAYLOAD OBJECT:${jaemzwareAuthPayloadObject} PAYLOAD STRING:${jaemzwareAuthPayloadString}`);
            if (!jaemzwareAuthPayloadObject.hasOwnProperty('authenticated')) throw new Error("DELETE: NO AUTHENTICATED STATUS" + jaemzwareAuthPayloadString);
            if (jaemzwareAuthPayloadObject.authenticated) {
                if (jaemzwareAuthPayloadObject.hasOwnProperty('message') && jaemzwareAuthPayloadObject.message === "password deleted") {
                    setShowDeleteForm(false);
                    setShowDeleteSuccess(true);
                } else {
                    throw new Error("DELETE: UNEXPECTED RESPONSE" + jaemzwareAuthPayloadString);
                }
            } else {
                throw new Error("DELETE: NOT AUTHENTICATED" + jaemzwareAuthPayloadString);
            }
        })
        .catch(err => {
            console.error("DELETE: FETCH COMMAND ERROR:" + err);
        })
        .catch((message) => {
                console.error("DELETE: SERVER ERROR:" + message);
            }
        );
}

function handleAudioPlay(element, analogArchiveBaseDomainUrl) {
    const audioPlayer = document.getElementById(element.target.id);
    const analogArchiveDotComMp3Location = audioPlayer.src.replace(analogArchiveBaseDomainUrl,"");
    const analogArchiveFetchRequest = analogArchiveBaseDomainUrl + "FindArtwork.php?filePath="+analogArchiveDotComMp3Location;
    fetch(analogArchiveFetchRequest)
        .then((res) => {
            const contentType = res.headers.get("content-type");
            if (contentType && contentType.indexOf("image/jpeg") !== -1) {
                return res.text();
            } else {
                throw new Error("WRONG CONTENT TYPE (" + contentType + ") WAS RETURNED FOR:" + analogArchiveFetchRequest);
            }
        }).then(function(data){
            const audioPlayerImage = document.getElementById(element.target.id.replace("audio", "albumart"));
            if (!data) {
                //set the default art
                audioPlayerImage.src = "images/record.jpg";
            } else {
                //set returned album art
                audioPlayerImage.src = data;
            }
        })
        .catch((message) => {
            console.warn(message);
        });;
}

function handleAudioEnded(element) {
    console.log(element.target.id + " AUDIO ENDED");
    var audioPlayers = document.querySelectorAll("audio");
    console.log("NUMBER OF AUDIO PLAYERS: " + audioPlayers.length);
    for (let i = 0; i < audioPlayers.length; i++){
        if(audioPlayers[i].id === element.target.id){
            if(i === audioPlayers.length-1){
                console.log("PLAY FIRST SONG:" + audioPlayers[0].id);
                audioPlayers[0].play();
            } else{
                console.log("PLAY NEXT SONG:" + audioPlayers[i+1].id);
                audioPlayers[i+1].play();
            }
        }
    }
}
export default App;
