import '../App.css';
import React from 'react';
function VideoPlayer(props){
    return(
        <div className="responsive-iframe">
            <video key={props.key} id={props.id} width="640" height="480"  preload="none" controls>
                <source src={props.value} type="video/mp4" />
            </video>
            <p><span><a href={props.value}>{props.value}</a></span></p>
        </div>
    );
}

export default VideoPlayer;