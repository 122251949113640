import React, {useState} from "react";

function AnalogArchiveMusicPlayer(props){
    const [start, setStart] = useState(0);
    const [rowcount,] = useState(10);
    return(
        <>
            <div className="App">
                <table cellPadding="10" cellSpacing="10" border="5">
                    <tbody>
                    {props.songs.slice(start,start+rowcount).map((value, index) => (
                        <tr key={"tr"+index} id={"tr"+index}>
                            <td className="songColumn" key={"tdimg"+index} id={"tdimg"+index}>
                                <img className="songImage" id={"albumart"+index} src="images/record.jpg" alt={"temp" + index} />
                            </td>
                            <td key={"td"+index} id={"td"+index}>
                                artist: {value[0]}<br /> album: {value[1]}<br /> track: {value[2]}<br /> title: {value[3]}<br />
                                <br />
                                <audio onEnded={(element) => props.handleAudioEnded(element)} onLoadStart={(element) => props.handleAudioPlay(element, props.analogArchiveBaseDomainUrl)} key={"audio"+index} id={"audio"+index} src={props.analogArchiveBaseDomainUrl + value[4]} controls preload="none">
                                    Your browser does not support the audio element.
                                </audio>
                            </td>
                        </tr>
                        ))
                    }
                    </tbody>
                </table>
                {/*NAVIGATION BUTTONS*/}
                <input type="button" key="first" id="first" value="|<<" onClick={
                    () => {
                        setStart(0);
                    }
                } />
                <input type="button" key="previous" id="previous" value="<<" onClick={
                    () => {
                        (start-rowcount < 0) ? setStart(0) : setStart(start-rowcount);
                    }
                } />
                <input type="button" key="next" id="next" value=">>" onClick={
                    () => {
                        (start+rowcount > props.songs.length) ? setStart(props.songs.length-1) : setStart(start+rowcount);
                    }
                } />
                <input type="button" key="last" id="last" value=">>|" onClick={
                    () => {
                        setStart(props.songs.length-10);
                    }
                } />
            </div>
        </>
    );
}

export default AnalogArchiveMusicPlayer;
