import '../App.css';
import React from 'react';
function PdfList(props){
    return(
        <>
            <div className="App">
                {
                    props.pdfs.map((value, index) => {
                        return(
                            <>
                                <embed key={"pdf" + index} id={"pdf" + index} src={value} className="pdfEmbed"/>
                                <br />
                            </>
                        );
                        }
                    )
                }
            </div>
        </>
    );
}

export default PdfList;