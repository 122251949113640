import '../App.css';
import React from 'react';
function ImageView(props){
    return (
            <a target="_blank" rel="noreferrer" href={props.value}>
                <img data-testid="images" id={"imageviewId" + props.index} key={"imageviewKey" + props.index}
                     alt={props.value} src={props.value}/>
            </a>
    );
}

export default ImageView;