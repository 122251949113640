import '../App.css';
import React from "react";

function HtmlDoc(props){
    return(
        <>
            <div className="responsive-iframe">
                <iframe src={props.htmlDoc} title="html doc" className="responsive-iframe"/>
            </div>
        </>
    );
}

export default HtmlDoc;